import React from 'react';
import { AnimateTextEnterByLineProps, useAnimateTextEnterByLine } from '../../hooks/useAnimateTextEnterByLine.hook';
import { useObservableRef } from '../../hooks/useObservableRef.hook';
import joinClassName from '../../utils/className.utils';
import './StandardTextBlock.scss';

type StandardTextBlockProps = AnimateTextEnterByLineProps & {
  className?: string
}

const StandardTextBlock: React.FC<StandardTextBlockProps> = props => {
  const ref = useObservableRef();
  useAnimateTextEnterByLine(ref, props);
  return <div className={joinClassName('StandardTextBlock', props.className)} ref={ref}>
    {props.children}
  </div>
}

export default StandardTextBlock;