import { Observer } from 'mobx-react-lite';
import React from 'react';
import CTAButton from '../components/CTAButton/CTAButton';
import PageTemplate from '../components/PageTemplate/PageTemplate';
import StandardTextBlock from '../components/StandardTextBlock/StandardTextBlock';
import { PageComponent } from '../types/gatsby.types';
import { ColorPalette } from '../utils/colors.utils';
import './thank-you.scss';

type PageThankYouProps = {}

const PageThankYou: PageComponent = props => {
  
  return <Observer children={() => (
    <PageTemplate 
      {...props} // always include
      className="PageThankYou"
      title="Thank You"
      backgroundColor={ColorPalette.white}
      foregroundColor={ColorPalette.dark}
    >
      <div className="ContactFormSuccessScreen">
        <div className="ContactFormSuccessScreenInner">
          <StandardTextBlock animateTextEnter={{ delay: 100 }}>
            <h2>Thank you for getting in touch!</h2>
            <p>We will get back to you as soon as possible. You should also receive a confirmation in your inbox.</p>
          </StandardTextBlock>
          <CTAButton to="/" title="Go back to homepage">Go to Homepage</CTAButton>
        </div>
      </div>
    </PageTemplate>
  )} />
  
}

export default PageThankYou;